import { Select, MenuItem, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { useTimezone } from '../../../store/timezoneContext';

const timezones = [
  'UTC', // Current Local Time
  'Asia/Dubai', // Gulf Standard Time (UTC+4)
  'Asia/Kolkata', // India Standard Time (UTC+5:30)
];

// const timezones = [
//   {shortName:'UTC', detailname: 'UTC'}, // Current Local Time
//   {shortName:'GST', detailname: 'Asia/Dubai'}, // Gulf Standard Time (UTC+4)
//   {shortName:'IST', detailname: 'Asia/Kolkata'}, // India Standard Time (UTC+5:30)
// ];

function Timezone() {
  const { timezone, setTimezone } = useTimezone();

  const handleChange = (event: any) => {
    setTimezone(event.target.value as string);
  };

  return (
    <FormControl size={"small"}  sx={{ m: 1, width: 150 }}>
      <InputLabel id="timezone-select-label">Timezone</InputLabel>
      <Select
        labelId="timezone-select-label"
        id="timezone-select"
        value={timezone}
        onChange={handleChange}
        input={<OutlinedInput label="Timezone" />}
      >
        {timezones.map((timezone) => (
          <MenuItem key={timezone} value={timezone}>
            {timezone}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Timezone;
