import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import config from '../constants/config';
import { ICommonReturnType, IGameData } from '../interfaces/interfaceCommon';
import getService from '../services/getService';
import LoginContext from './loginContext';

interface GamesContextType {
    games: IGameData[];
    fetchAllGames: (wlId: number) => void;
}

interface GamesProviderProps {
    children: ReactNode;
}

const GamesContext = createContext<GamesContextType | undefined>(undefined);

export const GamesProvider: React.FC<GamesProviderProps> = ({ children }) => {
    const [games, setGamesData] = useState([]);
    const loginCtx = useContext(LoginContext);

    const fetchAllGames = async (wlId: number | undefined) => {
        const url = `${config.cmsConfig.apiEndpoint.gameList}/15`;
        const result: ICommonReturnType = await getService(url);
        setGamesData(result.data);
    }

    useEffect(() => {
        if (loginCtx.isLogin) {
            console.log(loginCtx, "loginCtx");
            fetchAllGames(loginCtx.loginData?.wlId);
        }
    }, []);

    return (
        <GamesContext.Provider value={{ games, fetchAllGames }}>
            {children}
        </GamesContext.Provider>
    );
};

export const useGames = (): GamesContextType => {
    const context = useContext(GamesContext);
    if (context === undefined) {
        throw new Error('useGames must be used within a GamesProvider');
    }
    return context;
};
