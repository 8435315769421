const images = {
  logo: require("../assets/images/project-logo.png"),
  logoMobile: require("../assets/images/project-logo-mobile.png"),
  avt: require("../assets/images/avatar.avif"),
  logKey: require("../assets/images/Reset password-pana.svg"),
  dashboard: require("../assets/images/Revenue-cuate.svg"),
  notFound: require("../assets/images/Oops 404 Error with a broken robot-cuate.svg"),
};

export default images;
