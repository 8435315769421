import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { IAddEditBlogProps, ICommonReturnType, IEditedBlogDetails, ILanguageListProps, IThemeListProps } from "../../interfaces/interfaceCommon"
import { InputBox } from "../common/UI/inputBox/inputBox";
import Stack from "@mui/material/Stack/Stack";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Button from "@mui/material/Button/Button";
import SelectComponent from "../common/UI/select/selectComponent";
import getService from "../../services/getService";
import config from "../../constants/config";
import { UploadButton } from "../common/actionButtons/UploadButton";
import { useMutation } from "react-query";
import putService from "../../services/putService";
import toast from "react-hot-toast";
import postFormDataService from "../../services/postFormData";
import TinyMCEEditor from "../common/tinymceEditor/TinyMCEEditor";
import LoadingSpinner from "../common/UI/loadingSpinner/LoadingSpinner";
import { addEditBlogShema } from "../../validations/addEditBlogSchema";

export const AddEditBlog: React.FunctionComponent<IAddEditBlogProps> = ({ id, refetch, closeModal, modalId }) => {
    const [themeData, setThemeData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editRecordData, setEditRecordData] = useState<IEditedBlogDetails>({} as IEditedBlogDetails);
    const [languageData, setLanguageData] = useState([]);

    useEffect(() => {
        !!id && fetchEditedBlogDetails();
        fetchLanguageList();
        fetchThemeList();
    }, []);

    const fetchLanguageList = async () => {
        try {
            const url = config.cmsConfig.apiEndpoint.languageList;
            const result: ICommonReturnType = await getService(url);
            const languageList = result?.data.map((language: ILanguageListProps) => {
                return {
                    name: language.name,
                    id: language.id
                }
            })
            setLanguageData(languageList);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const fetchEditedBlogDetails = async () => {
        setIsLoading(true);
        try {
            const url = `${config.cmsConfig.apiEndpoint.editBlog}/${id}`;
            const result: ICommonReturnType = await getService(url);
            setEditRecordData(result.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    const fetchThemeList = async () => {
        try {
            const url = config.cmsConfig.apiEndpoint.themeList;
            const result: ICommonReturnType = await getService(url);
            const themeList = result?.data.map((theme: IThemeListProps) => {
                return {
                    name: theme.name,
                    id: theme.id
                }
            })
            setThemeData(themeList);
        } catch (error: any) {
            console.log(error.message);
        }
    }

    const editedBlogInitialState = {
        title: editRecordData.title || "",
        description: editRecordData.description || "",
        langId: editRecordData.langId || 0,
        blogUrl: editRecordData.blogUrl || "",
        sort: editRecordData.sort || 0,
        isActive: !!editRecordData.isActive,
        themeId: Number(!!editRecordData.themeId ? editRecordData.themeId : -1),
        imageName: editRecordData.imageName || ""
    }

    const initialValues = !!id ? editedBlogInitialState : {
        title: "",
        description: "",
        langId: "",
        blogUrl: "",
        sort: 0,
        isActive: false,
        themeId: -1,
        imageName: null
    };

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, isValid, dirty, resetForm, setFieldValue } = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: addEditBlogShema,
        onSubmit: (values) => {
            const formData = new FormData();
            const { title, description, langId, blogUrl, sort, isActive, themeId } = values;

            formData.append('title', title);
            formData.append('description', description);
            formData.append('langId', Number(langId) as any);
            formData.append('blogUrl', blogUrl);
            formData.append('sort', Number(sort) as any);
            formData.append('isActive', isActive.toString());
            formData.append('themeId', Number(themeId) as any);
            formData.append('imageName', values.imageName as any);
            addEditBlogMutation.mutate(formData);
        },
    });

    const addEditBlogMutation = useMutation({
        mutationFn: async (formData: FormData) => {
            const url = !!id ? `${config.cmsConfig.apiEndpoint.updateBlog}/${id}` : config.cmsConfig.apiEndpoint.createBlog;
            try {
                const result = !!id ? await putService(url, formData) : await postFormDataService(url, formData);
                return result;
            } catch (error) {
                throw error;
            }
        },
        onSuccess: (data) => {
            refetch();
            closeModal(modalId);
            toast.success(data.message);
            resetForm();
        },
        onError: (error: any) => {
            toast.error('Failed to create blog ' + error.response?.data?.message);
        },
    });

    const handleIconUpload = (file: File | null) => {
        setFieldValue("imageName", file);
    }

    const handleEditorChange = (content: string) => {
        setFieldValue('description', content);
    };

    return (isLoading ? <Stack minHeight={300}>{<LoadingSpinner small={true} />}</Stack> :
        <div className="model-inner-margintop">
            <form onSubmit={handleSubmit}>
                <Stack flexDirection={"row"} gap={1} justifyContent={"space-between"} marginBottom={2}>
                    <Stack width={"100%"}>
                        <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.title} name="title" id={"title"} label={"Title"} variant="outlined" errorMessage={errors.title} isTouched={touched.title} />
                    </Stack>
                    <Stack width={"100%"}>
                        <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.blogUrl} name="blogUrl" id={"blogUrl"} label={"Blog Url"} variant="outlined" errorMessage={errors.blogUrl} isTouched={touched.blogUrl} />
                    </Stack>
                    <Stack width={"100%"}>
                        <SelectComponent
                            value={values.langId}
                            options={languageData}
                            size="medium"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="langId"
                            name="langId"
                            label="Language"
                            labelId="langId"
                            errorMessage={errors.langId}
                            touched={touched.langId}
                        />
                    </Stack>
                </Stack>

                <Stack flexDirection={"row"} gap={1} justifyContent={"space-between"} marginBottom={2} alignItems={"center"}>
                    <Stack width={"100%"}>
                        <InputBox onChangeHandler={handleChange} onBlurHandler={handleBlur} value={values.sort} name="sort" id={"sort"} label={"Sort"} variant="outlined" errorMessage={errors.sort} isTouched={touched.sort} />
                    </Stack>
                    <Stack width={"100%"}>
                        <SelectComponent
                            value={values.themeId}
                            options={themeData}
                            size="medium"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="themeId"
                            name="themeId"
                            label="Theme"
                            labelId="themeId"
                            errorMessage={errors.themeId}
                            touched={touched.themeId}
                        />
                    </Stack>
                    <Stack flexDirection={"row"} gap={2} width={"100%"} justifyContent={"space-around"}>
                        <UploadButton onChange={(file) => handleIconUpload(file)} label={"Select image"} imgName={!!id ? editRecordData.imageName : null} imgUrl={!!id ? editRecordData.imageUrl : null}	/>
                        <FormControlLabel control={
                            <Checkbox
                                checked={values.isActive}
                                onChange={handleChange}
                                name="isActive"
                            />}
                            label="Active" />
                    </Stack>
                </Stack>

                <Stack marginBottom={2}>
                    <TinyMCEEditor value={values.description} handleEditorChange={handleEditorChange} />
                </Stack>

                <div className={`button_Wrapper`}>
                    <Button className="customBtn" disabled={!dirty} type="reset" onClick={() => resetForm()} size="small" variant="outlined">
                        {"Reset"}
                    </Button>
                    <Button className="customBtn" disabled={!isValid || !dirty} type="submit" size="small" variant="outlined">
                        {"submit"}
                    </Button>
                </div>
            </form>
        </div>
    )
}