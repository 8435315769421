import React from "react";
import ThemeBox from "./themeBox/ThemeBox";
// import LangBox from "./langBox/LangBox";
import ProfileMenu from "./profile/ProfileMenu";
import Timezone from "../../timezone/Timezone";
import classes from "./TopNavRightBox.module.scss";
import FullScreen from "./fullScreen/FullScreen";

function TopNavRightBox() {
  return (
    <div className={classes.topNavBox_right}>
      <div className={classes.wrapper}>
        {/* <LangBox /> */}
      </div>
      <Timezone/>
      <ThemeBox />
      <FullScreen />
      <ProfileMenu />
    </div>
  );
}
export default TopNavRightBox;