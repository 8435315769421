import * as Yup from "yup";

export const createBrandingSchema = Yup.object({
    themeId: Yup.string().required("Please select theme"),
    title: Yup.string().required("Please enter title"),
    type: Yup.string().required("Please select type"),
    attributeKey: Yup.string().required("Please enter attribute key"),
    attributeValue: Yup.string().required("Please enter attribute value"),
    displayFor: Yup.string().required("Please enter display for value"),
});
