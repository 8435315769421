import { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import getService from '../services/getService';
import { ICommonReturnType } from '../interfaces/interfaceCommon';
import config from '../constants/config';
import LoginContext from "../store/loginContext";
interface ModulePrivilageContextProps {
    canView: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canAdd: boolean;
    moduleList: Array<{id:number, name:string, Panel:string}>;
    updateData: (data: any) => void;
    fetchModulesNPrivilage: () => void;
}

const ModulePrivilageContext = createContext<ModulePrivilageContextProps | undefined>(undefined);

export const ModulePrivilageProvider = ({ children }: { children: ReactNode }) => {
  const [canView, setCanView] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [canAdd, setCanAdd] = useState<boolean>(false);
  const [moduleList, setModuleList] = useState<Array<{id:number, name:string, Panel:string}>>([]);
  const loginCtx = useContext(LoginContext);

  const updateData = (data: any) => {
    setCanView(data.canView === 'true' ? true : false);
    setCanEdit(data.canEdit === 'true' ? true : false);
    setCanDelete(data.canDelete === 'true' ? true : false);
    setCanAdd(data.canAdd === 'true' ? true : false);
    setModuleList(data.Module);
  };

  useEffect(() => {
    if(loginCtx.isLogin){
        fetchModulesNPrivilage();
    }
  }, []);

  const fetchModulesNPrivilage = async () => {
    try {
      const url = `${process.env.REACT_APP_CMS}${config.cmsConfig.apiEndpoint.profileMe}`;
      const params: {panelType: string} = {
        panelType: "CMS"
      }
      const result: ICommonReturnType = await getService(url, params);
      if (result?.data) {
        updateData(result?.data.role);
      } else {
        console.error('API response data structure is unexpected:', result);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setCanView(false);
      setCanEdit(false);
      setCanDelete(false);
      setCanAdd(false);
      setModuleList([]);
    }
  }

  return (
    <ModulePrivilageContext.Provider value={{ canView, canEdit, canDelete, canAdd, moduleList, updateData, fetchModulesNPrivilage }}>
      {children}
    </ModulePrivilageContext.Provider>
  );
};

export const useModulePrivilage = () => {
  const context = useContext(ModulePrivilageContext);
  if (!context) {
    throw new Error('useModulePrivilage must be used within a ModulePrivilageProvider');
  }
  return context;
};

export default ModulePrivilageContext;