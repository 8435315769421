import { useContext } from "react";
import { useWindowSize } from "usehooks-ts";
import TopNavRightBox from "./rightBox/TopNavRightBox";
import SidebarContext from "../../../store/sidebarContext";
import classes from "./TopNav.module.scss";
import { useLocation } from 'react-router-dom';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseIcon from '@mui/icons-material/Close';
import config from "../../../constants/config";

function TopNav() {
  const sideOpenCtx = useContext(SidebarContext);
  const { width } = useWindowSize();

  function openSidebarHandler() {
    sideOpenCtx.toggleSidebar();
    if (width <= 768) document.body.classList.toggle("sidebar__open");
  }

  const location = useLocation();
  const currentPath = location.pathname;

  const pageTitles = config.cmsConfig.pageTitles;

  const currentTitle = pageTitles.find((title) => title.path === currentPath)?.title || "";

  return (
    <div className={classes.topNav}>
      <div className={classes.topNav_left}>
        <div
          className={classes.topNav_left_menu_icon}
          onClick={openSidebarHandler}
        >
          {/* <Icon icon="ci:menu-alt-03" width="24" /> */}
          <MenuOutlinedIcon color="primary" />
        </div>
        <div >
          <div className={classes.pagTitle}>{currentTitle}</div>
        </div>
        <div className={classes.topNav_left_menu} onClick={openSidebarHandler}>
          <div className="topNav_left_menu_open">
            <MenuOutlinedIcon />
          </div>

          <div className="topNav_left_menu_close">
            <CloseIcon />
          </div>
        </div>
      </div>
      <TopNavRightBox />
      <br />
    </div>
  );
}
export default TopNav;