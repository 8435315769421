import moment from 'moment-timezone';
import { useTimezone } from '../store/timezoneContext';

export const useTimezoneConverter = () => {
  const { timezone } = useTimezone();

  const convertToTimezone = (timestamp: string | number) => {
    return moment(timestamp).tz(timezone).format('DD/MM/YYYY HH:mm'); //'DD/MM/YYYY HH:mm'
  };

  return { convertToTimezone };
};