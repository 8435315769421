import { useEffect, useState } from "react"
import { IThemeListProps, ICommonReturnType } from "../../interfaces/interfaceCommon";
import config from "../../constants/config";
import getService from "../../services/getService";
import { GridAlignment, GridColDef } from "@mui/x-data-grid";
import { DataTable } from "../common/UI/table/dataTable";
import LoadingSpinner from "../common/UI/loadingSpinner/LoadingSpinner";
import { useModal } from "../../store/modalContext";
import { ActionButtons } from "../common/actionButtons/ActionButtons";
import { DeleteModal } from "../common/actionButtons/DeleteModal";
import { AddEditBlog } from "./AddEditBlog";
import { useModulePrivilage } from "../../store/modulePrivilageContext";

export const Blog = () => {
    const [rowData, setRowData] = useState<IThemeListProps[]>([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const { openModal, closeModal, modalId } = useModal();
    const { canAdd, canEdit, canDelete } = useModulePrivilage();
    useEffect(() => {
        fetchBlogData();
    }, []);

    const fetchBlogData = async () => {
        setIsDataLoading(true);
        try {
            const url = config.cmsConfig.apiEndpoint.blogList;
            const response: ICommonReturnType = await getService(url);
            setRowData(response.data);
        } catch (error) {
            console.log("failed to load blog data")
        } finally {
            setIsDataLoading(false);
        }
    }

    const createColumns = () => {
        const columns: GridColDef[] = [
            {
                field: 'id', headerName: 'ID', resizable: false, headerAlign: "center",
                align: "center"
            },
            {
                field: 'title', headerName: 'Title', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },

            {
                field: 'imageName', headerName: 'Image Name', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },
            {
                field: 'blogUrl', headerName: 'Blog Url', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },
            {
                field: 'sort', headerName: 'Sort', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center"
            },
            {
                field: 'isActive', headerName: 'Status', sortable: false, resizable: false, flex: 1, headerAlign: "center",
                align: "center",
                renderCell: (params) => {
                    return (
                        <span>{!!params.row.isActive ? "Active" : "In-Active"}</span>
                    );
                },
            },
            ...((canEdit || canDelete) ? [
                {
                    field: 'Actions', headerName: 'Actions', sortable: false, resizable: false, flex: 1, headerAlign: "center" as GridAlignment,
                    align: "center" as GridAlignment, renderCell: (params: any) => {
                        return (
                            <ActionButtons
                                name={params.row.title}
                                isFullwidth={true}
                                deleteModal={<DeleteModal closeModal={closeModal} modalId={modalId} name={params.row.title} refetch={fetchBlogData} deleteUrl={`${config.cmsConfig.apiEndpoint.deleteBlog}/${params.row.id}`} />}
                                editModal={<AddEditBlog closeModal={closeModal} modalId={modalId} refetch={fetchBlogData} id={params.row.id} />}
                            />
                        )
                    },
                },
            ] : []),
        ];
        return columns
    };

    const handleActionButtonClick = () => {
        const modalContent = <AddEditBlog closeModal={closeModal} modalId={modalId} refetch={fetchBlogData} />;
        openModal(modalContent, "Add Blog", true)
    }

    return (
        <div>
            {isDataLoading ? <LoadingSpinner /> :
                <DataTable rowData={rowData}
                    columns={createColumns()}
                    rowsPerPageOptions={[10, 25]}
                    shouldShowDatePicker={false}
                    shouldShowSearch={true}
                    shouldShowActionButton={canAdd ? true : false}
                    onClickActionButton={handleActionButtonClick}
                />
            }
        </div>
    )
}