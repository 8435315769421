import React from 'react';
import { FormControl, Select, MenuItem, InputLabel, OutlinedInput } from '@mui/material'; // Import Material-UI components
import { ISelectProps } from '../../../../interfaces/select';
import { shouldShowError } from '../../../../utils/utils';
import { FormError } from '../../FormError/FormError';

const SelectComponent: React.FC<ISelectProps> = ({
    value,
    options,
    onChange,
    onBlur,
    name,
    defaultMenuItem,
    errorMessage,
    touched,
    size,
    labelId,
    label
}) => {

    return (
        <div>
            <FormControl sx={{ minWidth: 120, width: '100%' }} size={size ? size : "medium"}>
                {label && <InputLabel id={labelId}>{label}</InputLabel>}
                <Select
                    labelId={labelId}
                    id={name}
                    value={value}
                    fullWidth
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={label}
                >
                    <MenuItem disabled value={value}>
                        {defaultMenuItem || "Select options"}
                    </MenuItem>
                    {options.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {errorMessage && <div>
                {shouldShowError(errorMessage, touched) && <FormError error={errorMessage} />}
            </div>}
        </div>
    );
};

export default SelectComponent;
