import React from 'react';
import { FormControl, FormLabel } from '@mui/material';
import { useField } from 'formik';
import { IColorPickerInputProps } from '../../../interfaces/interfaceCommon';


export const ColorPickerInput: React.FC<IColorPickerInputProps> = ({ ...props }) => {
    return (
        <FormControl fullWidth>
            <input
                type="color"
                {...props}
                value={props.value}
                onChange={props.onChange}
                style={{ width: '100%', height: '15px', border: 'none', cursor: 'pointer' }}
            />
        </FormControl>
    );
};

