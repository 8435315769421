
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTimezoneConverter } from '../../../hook/useTimeZoneConverter';


const TimezoneCellRenderer = (params: GridRenderCellParams<any>) => {
  const { convertToTimezone } = useTimezoneConverter();
  return <div>{convertToTimezone(params.value)}</div>;
};

export default TimezoneCellRenderer;
