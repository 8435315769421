import { ILoaderSpinnerProps } from "../../../../interfaces/interfaceCommon";
import classes from "./LoadingSpinner.module.scss";

const LoadingSpinner: React.FC<ILoaderSpinnerProps> = ({small = false}) => {
  return (
    <div className={classes.spinner__wrapper}>
      {small ? <div className={classes.spinnerSmall}></div> : <div className={classes.spinner}></div>}
    </div>
  );
};

export default LoadingSpinner;
