import moment from "moment";
import { displayOptions } from "../constants/data";

export function isNonEmptyArr<T>(array: T[]): boolean {
  return Array.isArray(array) && array.length !== 0;
}

export const shouldShowError = (error: string | undefined,
  touched: boolean | undefined) => {
  return error && touched
}

export const formatDate = (date: Date | null) => {
  return moment(date).format('DD/MM/YYYY HH:mm');
};

export const setLocalStorageData = (data: any) => {
  localStorage.setItem('token', data.accessToken);
  localStorage.setItem('user', data.userName);
}

export const removeLocalStorageData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('isLogin');
}

export const formatIndianPayment = (number: string | null) => {
  let numStr = String(number);

  numStr = numStr.replace(/\B(?=(\d{2})+(?!\d))/g, ",");

  return numStr;
};

export const isValidURL = (url: string) => {
  const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-zA-Z0-9$_.+!*,;:&=-]|%[0-9a-fA-F]{2})+:)?([a-zA-Z0-9$_.+!*,;:&=-]|%[0-9a-fA-F]{2})+@)?' + // authentication
    '(((\\d{1,3}\\.){3}\\d{1,3})|(([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}))' + // hostname or IP
    '(\\:\\d+)?' + // port
    '(\\/[-a-zA-Z0-9$_.+!*,;:@&=-]*)*' + // path
    '(\\?[;&a-zA-Z0-9$_.+!*,;:@&=-]*)?' + // query string
    '(\\#[-a-zA-Z0-9$_.+!*,;:@&=-]*)?$', 'i'); // fragment locator

  return urlPattern.test(url);
};

export const displayForEditedRecord = (edutedvalue: string) => {
  const editedRecordValue = displayOptions.filter((value) => value.name === edutedvalue)[0]?.id || -1;
  return editedRecordValue as number;
}

export const displayForParams = (displayFor: any) => {
  const displayForParams: string = displayOptions.filter((value) => value.id === displayFor)[0]?.name || "";
  return displayForParams;
}
